import * as types from '../mutation-types'

export const fetchOrderShipments = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchOrderShipments')

  return new Promise((resolve, reject) => {
    model
      .fetchOrderShipments(options)
      .then((response) => {
        // dispatch(
        //   'orders/receiveResourcesFromRelationshipsWithReplace',
        //   response,
        //   {
        //     root: true
        //   }
        // )
        // dispatch('orderItems/receiveResourcesFromRelationships', response, {
        //   root: true
        // })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchOrderShipments')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrderShipments,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
