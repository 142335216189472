import * as types from '../mutation-types'
import SiteConfig from '../../../resource_models/site_config'

export const searchAddress = ({ dispatch, commit }, { keyword }) => {
  commit(types.API_REQUEST_START, 'searchAddress')

  return new Promise((resolve, reject) => {
    SiteConfig.searchAddress(keyword)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: searchAddress,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateLineBotConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateLineBotConfig')

  return new Promise((resolve, reject) => {
    model
      .updateLineBotConfig()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateLineBotConfig,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
