import * as types from '../mutation-types'

export const fetchPromoterMemberInterests = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterMemberInterests')

  return new Promise((resolve, reject) => {
    model
      .fetchPromoterMemberInterests(options)
      .then((response) => {
        dispatch(
          'partnerPromoterMemberInterests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchPromoterMemberInterests')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterMemberInterests,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updatePromoterMemberInterest = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updatePromoterMemberInterest')

  return new Promise((resolve, reject) => {
    model
      .updatePromoterMemberInterest(form)
      .then((response) => {
        dispatch(
          'partnerPromoterMemberInterests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'updatePromoterMemberInterest')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePromoterMemberInterest,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
