import * as types from '../mutation-types'

export const fetchIndustryCategoryShips = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchIndustryCategoryShips')

  return new Promise((resolve, reject) => {
    model
      .fetchIndustryCategoryShips(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchIndustryCategoryShips')

        dispatch(
          'partnerIndustryCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchIndustryCategoryShips,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const batchSaveIndustryCategoryShips = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'batchSaveIndustryCategoryShips')

  return new Promise((resolve, reject) => {
    model
      .batchSaveIndustryCategoryShips(form)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'batchSaveIndustryCategoryShips')

        dispatch(
          'partnerIndustryCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: batchSaveIndustryCategoryShips,
            ref: {
              dispatch,
              commit
            },
            params: { model, shippingCategory }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
